var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Object.keys(_vm.message).length)?_c('div',{staticClass:"post-questionnaire mt-5"},[_c('div',{staticClass:"post-questionnaire__content"},[_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(_vm.$t('field_post_questionnaire_text_title'))+" ")]),(_vm.topics !== undefined)?_c('topics-select',{staticClass:"mt-2",attrs:{"selected-topics":_vm.message.topics,"topics":_vm.topics},on:{"update-topics":function($event){_vm.message.topics = $event}}}):_vm._e(),_c('text-input',{staticClass:"questionnaire-field",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.message,
            'key': 'text',
            'prefix': 'post_questionnaire_',
            'titleVisible': false,
            disabled: _vm.disabled,
          }
        }}})],1),(_vm.questionsFrontend.length)?_c('a-checkbox-group',{staticClass:"w-full",model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}},_vm._l((_vm.questionsFrontend),function(option,index){return _c('a-checkbox',{key:option.key,staticClass:"mb-2 last:mb-0 mx-0 block flex justify-between items-center",attrs:{"value":option.value,"disabled":_vm.isCheckboxDisabled(option.value)}},[(!option.visible)?[_vm._v(" "+_vm._s(option.value)+" ")]:_vm._e(),(option.visible)?_c('a-input',{on:{"pressEnter":function($event){return _vm.handleQuestionItemEditClick(option, index)}},model:{value:(option.value),callback:function ($$v) {_vm.$set(option, "value", $$v)},expression:"option.value"}}):_vm._e(),_c('div',{staticClass:"w-full text-right"},[_c('a-button',{class:{'active' : option.visible},attrs:{"icon":"edit","disabled":_vm.disabled},on:{"click":function($event){return _vm.handleQuestionItemEditClick(option, index)}}}),_c('a-button',{staticClass:"ml-2",attrs:{"type":"danger","icon":"delete","disabled":_vm.disabled || _vm.message.correct_option_id === option.value},on:{"click":function (){ return _vm.questionsFrontend.splice(index, 1); }}})],1)],2)}),1):_vm._e(),_c('div',{staticClass:"add_value_wrapper flex items-center gap-2"},[_c('text-input',{staticClass:"w-full",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': this,
            'key': 'newOption',
            'prefix': 'post_',
            'titleVisible': false,
            disabled: _vm.disabled
          }
        }},on:{"pressEnter":function($event){!_vm.newOption || _vm.disabled ? false : _vm.addNewOption()}}}),_c('a-button',{staticClass:"btn-success mt-2",attrs:{"icon":"plus","disabled":!_vm.newOption || _vm.disabled},on:{"click":_vm.addNewOption}})],1),(_vm.message.type === 'Quiz')?_c('text-input',{staticClass:"quiz_fields",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.message,
          'key': 'explanation',
          'prefix': 'post_questionnaire_',
          disabled: _vm.disabled,
        }
      }}}):_vm._e(),(_vm.message.type === 'Poll')?_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.message,
          'key': 'allows_multiple_answers',
          'prefix': 'post_questionnaire_',
          'disabled':_vm.disabled
        }
      }}}):_vm._e(),_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.message,
          'key': 'is_anonymous',
          'prefix': 'post_questionnaire_',
          'disabled':_vm.disabled
        }
      }}}),_c('time-unit-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args':{
          'model': _vm.message,
          'key': 'open_period',
          'prefix': 'post_questionnaire_',
          'validation': 'min_value:0',
          'step': _vm.StepUnit.Second,
          'units': [_vm.StepUnit.Second, _vm.StepUnit.Minute],
          'disabled':_vm.disabled
        },
      }}}),(_vm.message.open_period > 600)?_c('span',{staticClass:"ml-2",staticStyle:{"color":"rgba(var(--a-danger), 1)","font-size":"80%"}},[_vm._v(" "+_vm._s(_vm.$t('post_quiz_period_warning'))+" ")]):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }